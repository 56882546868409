
body {
    /* font-family: 'Sarabun',  !important; */
    font-family: 'Kanit',normal !important;
    background-color: red;
  }
.row-between { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.row-flexend {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 10px;

}
.row-center {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

}
.td-overall {
  font-size: 0.8vw;
  cursor: pointer;
}

.td-content-product {
  font-size: 1vw;
  cursor: pointer;

}
.td-contentaccount {
  font-size: 1.2vw;
  cursor: pointer;
}
.font-tital{
  font-size: 0.4cm;
  color:#2898cb ;
}
.app.flex-row.align-items-center{

  background-image: linear-gradient(269.83deg, #DDD9DB 2.83%, rgba(240, 241, 243, 0) 111.57%);
}
.font-a{
  font-size: 0.3cm;
  color:#2898cb;

}
.headform-create {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  width: 100%;
  padding: 40px 50px 0px 5px;
}
.headform-create div{
 

  display: flex;
  flex-direction: row;
  align-items: center;


}

.head-doc-name{
  font-size: 0.4cm;
  margin-left: 5px;
  margin-bottom: 0px;
}
.head-doc{
  font-size: 14px;
  color: #2898cb;
}



.card-container {
  margin-top: 10px ;
  margin :20px;
 
  border-radius: 0.25rem !important;
  

}
.card-container-end{
  display: flex;
  justify-content: flex-end;
 
  margin-bottom: 5px;
}
.card-form-start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.card-form-center{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ant-select-selector{
  border-radius: 5px !important;
}
.label-inspace{
 white-space: nowrap !important;
}
.label-normal{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.border-line{
  margin-top: 20px;
  border-bottom:#e6e9ee solid 2px;
  margin-bottom: 20px;
}

.editable-cell {
  position: relative;
}
.ant-table thead >tr >th{
  background: rgb(224,224,224,0.5);
}
.ant-table.ant-table-bordered .ant-table-container{
  border: 1px solid rgb(224,224,224,0.8);
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  /* margin-top: 10px; */
  color: #666;
}

.column-center {
  display: table-cell;
  text-align: start;
  vertical-align: middle;
  padding-top: 4px;
}
.contain-margincusPO {
  margin-top: 3px;
}
.modal-scroll {
  height: 250px;
  overflow: scroll;
}

.disabled-row {
  background-color: #FAFAFA;
  pointer-events: none;
}

.test-css {
  font-size: 20px;
  color: red;
}

.account-form {
  padding: 50px 30px;
  @media (max-width: 575.98px) {
    padding: 30px 16px;
  }
}

.account-image{
  width: 15%;
  height: 15%;
  @media (max-width:575.98px) {
    width: 25%;
    height:25%;
  }
}