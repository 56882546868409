html body {
  font-family: "Kanit", "Roboto", sans-serif !important;
}
html body .app.flex-row.align-items-center {
  height: 100vh;
}
#root {
  height: 100%;
}
// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
