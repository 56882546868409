// Here you can add other styles
.img-root {
  text-align: center;
}
.img {
  width: 160px;
}
.rigis-root {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 40px 0px 40px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 35px;
  width: 100%;
}
.td-content {
  font-size: 0.8vw;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 1.3vw;
  }
}
.header {
  padding-left: 105px;
  padding-right: 105px;
  padding-top: 15px;
  @media (max-width: 575.98px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.header-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
}
.regis-form {
  padding: 30px 105px;
  @media (max-width: 575.98px) {
    padding: 30px 16px;
  }
}
.ant-picker {
  width: 100%;
}
.ant-customNumber{
  div.ant-input-number-handler-wrap{
    display: none;
  }
  input.ant-input-number-input { 
    text-align: right; 
    padding: 0 0 0 5px;
  }
}

.btn-register:hover,
.btn-register:focus {
  background-color: "#4743e0";
  border-color: #4743e0;
  // .ant-btn-primary:hover,
  // .ant-btn-primary:focus {
  //   background-color: #4743e0;
  //   border-color: #4743e0;
  // }
}
.ant-table-tbody > tr >th{
  border-bottom: 3px solid #4743e0 !important;
  //color: white;
  //background: #3071b9 !important;
  }
.button-export{
  background: #1D6F42;
  border-color: #1D6F42;
}
.button-export:hover{
  background: rgba(29, 111, 66, 0.8);
  border-color: rgba(29, 111, 66, 0.8); 
}
.button-export:focus{
  background: rgba(29, 111, 66, 0.8);
  border-color: rgba(29, 111, 66, 0.8); 
}
.line {
  opacity: 0.2;
  border-top: 1px dashed #000000;
}
.term:not([href]):not([tabindex]) {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}
.term:not([href]):not([tabindex]):hover,
.term:not([href]):not([tabindex]):focus {
  color: #167495;
  text-decoration: underline;
}
// .ant-modal-footer {
//   display: none;
// }
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}
.ant-input {
  border-radius: 5px;
}
.ant-input-group-addon {
  border-radius: 5px;
}
.ant-picker {
  border-radius: 5px;
}
.ant-picker-suffix {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.anticon {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.rigis-root .input-split {
  width: 30px;
  border: 0px;
  // border-left: 0px;
  // border-right: 0px;
  pointer-events: none;
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
  margin: 0px 5px;
  @media (max-width: 768px) {
    width: 5px;
    padding: 4px 0px;
  }
}

//splash screen
.splash-root {
  width: 851px;
  margin: auto;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  @media (max-width: 575.98px) {
    width: 100%;
    height: 50%;
  }
}
.fade-in-image {
  // width: 851px;
  // margin: auto;
  height: 100%;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar {
  background-color: rgba(48, 53, 57, 0.8);
}
.sidebar {
  background-color: rgba(48, 53, 57, 0.8);
}
