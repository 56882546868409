.custom-card {
    background: #FAFAFA;
    border:1px solid rgba(115, 129, 143, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 20px;
  }
.custom-card-success {
    background: #FAFAFA;
    border:1px solid #8FC95E;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
  }
.custom-label-success {
      color: #8FC95E;
      padding: 0px 5px 0px 5px;
      margin-top: -5px;
      font-size: 18px;
  }
.custom-card-pending {
    background: #FAFAFA;
    border:1px solid #F0B26B;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
  }
.custom-label-pending {
    color: #F0B26B;
    font-size: 18px;
    padding: 0px 5px 0px 5px;
    margin-top: -5px;
}
.custom-label-totol {
    color: #8FC95E;
    font-size: 22px;
    padding: 0px 5px 0px 5px;
    margin-top: -7px;
}
.custom-label-head {
    font-size: 16px;
    font-style: normal;
}
.custom-label-cash {
    margin-left: 5px;
}
.custom-label-credit {
    margin-right: 10px;
}
.custom-doughnut{
    width: 80%;
    height: 80%;
}

