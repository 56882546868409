

.account-image-content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .image{
        width: 15%;
        height: 15%;
        @media (max-width:575.98px) {
          width: 35%;
          height:35%;
        }
    }

}
