.card-container-login{
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  //width: 850px;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}
.logo-container {
  margin-top: 80px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}
.form-container{
  margin-left: 10px;
  margin-right: 30px;
  .div{
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .col-button{
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
  
  }.col-justify{
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
  }.p-forget{
    font-size: 14px;
    color: #C4C4C4;
    text-decoration-line: underline;
    margin-right: 0;
  }
}
.container-logo{
  display: flex;
  align-items: center;
  bottom: 0;
}
.p-remmain{
  font-family: 'Kanit',Thin;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.8)
}

.btn-login{
  width: 80%;
  border-radius:30px;
  height:45px ;
  font-size:18px ;
  font-family: 'kanit',normal;
 
}


.input-line{
    width:100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #C4C4C4;
    box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
}
.input-line:hover{
  width:100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #C4C4C4;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
}
.input-line:focus{
  width:100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #C4C4C4;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
}







.container-login {
  .desktop {
    .content{
      padding-top: 50px;
      padding-left:-40px ;
      img{
        display: flex;
        align-items: flex-start;
        bottom: 0;
      }
    }
  }
}